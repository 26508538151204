import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const defaultStory = [
  {
    image: "",
    title: "",
    subtitle: "",
    text: "",
    location: "",
    location_url: "",
  },
];

export const Story = ({ title }) => {
  const [stories, setStories] = useState(defaultStory);

  const fetchStories = () => {
    fetch(`https://admin.keesenbo.nl/api/stories.json`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setStories(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchStories();
  }, []);

  return (
    <section className="stories">
      <div className="container">
        <h1>{title}</h1>
        <div className="stories-content">
          {stories &&
            stories.map((story, index) => (
              <div className="stories-content-item" key={index}>
                {story.image && (
                  <div
                    className={`stories-content-item-image ${
                      index % 2 && "--reverse"
                    }`}
                  >
                    <img
                      src={`https://admin.keesenbo.nl/images/${story.image}`}
                      alt={`story_${index}`}
                    />
                  </div>
                )}
                <div className="stories-content-item-block">
                  <div
                    className={`stories-content-item-block-textblock ${
                      index % 2 && "--reverse"
                    } ${!story.image && "--noimage"}`}
                  >
                    <div className="stories-content-item-block-textblock-title">
                      {story.title}
                    </div>
                    <div className="stories-content-item-block-textblock-subtitle">
                      {story.subtitle}
                    </div>
                    <div
                      className="stories-content-item-block-textblock-text"
                      dangerouslySetInnerHTML={{ __html: story.text }}
                    />
                    {story.location && (
                      <div className="stories-content-item-block-textblock-location">
                        {story.location_url ? (
                          <Link to={story.location_url} target="_blank">
                            <i className="fa-solid fa-location-dot" />
                            {story.location}
                          </Link>
                        ) : (
                          story.location
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
