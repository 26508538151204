const defaultMoC = [
  {
    emailaddress: "",
    forname: "",
    surname: "",
    telephonenumber: "",
  },
];

export const MasterOfCeremony = ({ settings }) => {
  const MoC = settings ? settings[0].mastersOfCeremony : defaultMoC;
  return (
    <section className="moc">
      <div className="container">
        <div className="moc-content">
          <div className="moc-content-title">Ceremonie meester</div>
          <div className="moc-content-text">
            {MoC.map((master, index) => (
              <div key={index}>
                <div className="moc-content-text-master">
                  {master.forname} {master.surname}
                </div>
                <div className="moc-content-text-phone">
                  {master.telephonenumber}
                </div>
                <div className="moc-content-text-email">
                  {master.emailaddress}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
