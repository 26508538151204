// Slideshow.js
import React, { useState, useEffect } from "react";
import "../styles/Photoviewer.css";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useLocation, Navigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";

const watermarkImage = "/logo.png";

const Slideshow = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { height } = useWindowDimensions();
  const location = useLocation();
  const key = new URLSearchParams(location.search).get("key");
  const { get } = useFetch();
  const [images, setImages] = useState([]);

  useEffect(() => {
    get("photos").then((data) => {
      setImages(data);
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
      }, 5000);

      return () => clearInterval(interval);
    });
    // eslint-disable-next-line
  }, []);

  function imageOrientation(src) {
    const img = new Image();
    img.src = src;

    return img.naturalWidth > img.naturalHeight ? "landscape" : "portrait";
  }

  const getExtension = (filename) => {
    return filename.split(".").pop().toLowerCase();
  };

  if (key === "123456789") {
    return (
      <div className="slideshow-container">
        <img src={watermarkImage} alt="login" className="login-icon" />
        {images.map((image, index) => (
          <div key={index}>
            <div
              key={index}
              className={`slide ${index === currentIndex ? "active" : ""}`}
            >
              {getExtension(image.imagePath) !== "mp4" && (
                <img
                  src={`https://admin.keesenbo.nl/images/${image.imagePath}`}
                  alt={`Slide ${index + 1}`}
                  style={{
                    objectFit: `${
                      imageOrientation(image.imagePath) === "portrait"
                        ? "none"
                        : "cover"
                    }`,
                    height: `${
                      imageOrientation(image.imagePath) === "portrait"
                        ? `${height}px`
                        : "100vh"
                    }`,
                    width: `${
                      imageOrientation(image.imagePath) === "portrait"
                        ? "auto"
                        : "100%"
                    }`,
                  }}
                />
              )}
            </div>
            {image.caption && (
              <div
                className={`caption ${index === currentIndex ? "active" : ""}`}
                dangerouslySetInnerHTML={{ __html: image.caption }}
              ></div>
            )}
          </div>
        ))}
      </div>
    );
  } else {
    return <Navigate to="/" replace={true} />;
    // return <div>nee</div>;
  }
};

export default Slideshow;
