import Icon from "@mdi/react";
import * as mdiIcons from "@mdi/js";
import { useEffect, useState } from "react";

const defaultPracticalInformation = [
  {
    image: "",
    question: "",
    answer: "",
  },
];

export const PracticalInformation = ({ title }) => {
  const [information, setInformation] = useState(defaultPracticalInformation);

  const fetchInformation = () => {
    fetch(`https://admin.keesenbo.nl/api/practical_informations.json`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInformation(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  useEffect(() => {
    fetchInformation();
  }, []);

  return (
    <section className="practicalInformation">
      <div className="container">
        <h1>{title}</h1>
        <div className="practicalInformation-content">
          {information &&
            shuffle(information).map((info, index) => (
              <div className="practicalInformation-content-item" key={index}>
                <Icon path={mdiIcons[info.image]} size={2} />
                <div className="practicalInformation-content-item-question">
                  {info.question}
                </div>
                <div className="practicalInformation-content-item-answer">
                  {info.answer}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
