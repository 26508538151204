import { useEffect } from "react";
import { useNavigate, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  console.error(error);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => clearTimeout(redirectTimeout);
  }, [navigate]);

  return (
    <section className="errorPage">
      <h1>Deze pagina bestaat niet!</h1>
      <p>
        Het lijkt erop dat je per ongeluk op een pagina terecht bent gekomen die
        niet bestaat.
      </p>
      <p>
        Je wordt over een paar seconden automatisch naar de homepagina gestuurd.
      </p>
      <p>{/* <i>{error.statusText || error.message}</i> */}</p>
    </section>
  );
}
