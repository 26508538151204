import { useEffect, useRef, useState } from "react";
import { Spinner } from "../components/Spinner";
import {
  TTL_PASSKEY,
  TTL_RESERVATION,
  getWithExpiry,
  setWithExpiry,
} from "../components/helpers";
import useWindowDimensions from "../hooks/useWindowDimensions";

const defaultGifts = [
  {
    title: "",
    toReservate: false,
    checked: false,
    image: "",
  },
];

export const Gifts = ({ title }) => {
  const [gifts, setGifts] = useState(defaultGifts);
  const [showModal, setShowModal] = useState(false);
  const [showLogin, setShowLogin] = useState(true);
  const [giftToReservate, setGiftToReservate] = useState();
  const [showMaxReservationModal, setShowMaxReservationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [clickedGift, setClickedGift] = useState(false);
  const passkey = useRef();
  const { width } = useWindowDimensions();

  const onMobile = width < 1024;

  let modalTimeout;
  let loginTimeout;

  const fetchGifts = () => {
    fetch(`https://admin.keesenbo.nl/api/presents.json`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setGifts(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchGifts();
    // eslint-disable-next-line
  }, []);

  const handleClick = (gift) => {
    if (onMobile) {
      return setClickedGift(gift.id);
    }
    window.open(gift.url, "_blank");
  };

  const handleReservation = (e, gift) => {
    if (e !== "handle") e.stopPropagation();
    const reservationTTL = localStorage.getItem("reservationTTL");
    if (!reservationTTL || Date.now() > reservationTTL) {
      const passkeyFromStorage = getWithExpiry("passkey");
      if (passkeyFromStorage) {
        fetch(`https://admin.keesenbo.nl/emailgift`, {
          method: "POST",
          headers: { "Content-Type": "multipart/form-data" },
          body: JSON.stringify({
            passkey: passkeyFromStorage,
            giftId: gift.id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.error(error);
          });

        fetch(`https://admin.keesenbo.nl/updategift`, {
          method: "POST",
          headers: { "Content-Type": "multipart/form-data" },
          body: JSON.stringify({
            passkey: passkeyFromStorage,
            giftId: gift.id,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.response === "OK") {
              localStorage.setItem(
                "reservationTTL",
                Date.now() + TTL_RESERVATION
              );
              setTimeout(fetchGifts, 100);
            }
          })
          .catch((error) => console.error(error));
      } else {
        setGiftToReservate(gift);
        setShowModal(true);
      }
    } else {
      setShowMaxReservationModal(true);
      modalTimeout = setTimeout(() => setShowMaxReservationModal(false), 10000);
    }
  };

  useEffect(() => {
    const passkeyFromStorage = getWithExpiry("passkey");
    if (passkeyFromStorage) {
      fetchInviteeData(passkeyFromStorage);
    }
    // eslint-disable-next-line
  }, []);

  const outsideClickHandler = (e) => {
    clearTimeout(modalTimeout);
    clearTimeout(loginTimeout);
    setGiftToReservate(null);
    setShowMaxReservationModal(false);
    setShowLogin(true);
    setLoading(false);
    setShowModal(false);
  };

  const fetchInviteeData = (key) => {
    fetch(`https://admin.keesenbo.nl/user`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: JSON.stringify({ passkey: key }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.response === "OK") {
          modalTimeout = setTimeout(() => {
            setWithExpiry("passkey", key, TTL_PASSKEY);
            setLoading(false);
            setShowLogin(false);
            if (giftToReservate) {
              handleReservation("handle", giftToReservate);
            }
            loginTimeout = setTimeout(() => {
              setShowModal(false);
              setShowLogin(true);
            }, 5000);
          }, 3000);
        } else {
          modalTimeout = setTimeout(() => {
            setLoginError(true);
            // passkey.current.value = "";
            setLoading(false);
            setShowLogin(true);
          }, 3000);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const formSubmitHandler = () => {
    setLoginError(false);
    setLoading(true);
    if (passkey) fetchInviteeData(passkey.current.value);
  };

  return (
    <section className="gifts">
      {showMaxReservationModal && (
        <>
          <div className="overlay" onClick={outsideClickHandler} />
          <div className="modal">
            <div className="modal-content">
              <div className="modal-content-title">Even geduld nog</div>
              <span>Je kan maar één cadeau per 24 uur reserveren.</span>
            </div>
          </div>
        </>
      )}
      {showModal && (
        <>
          <div className="overlay" onClick={outsideClickHandler} />
          <div className="modal">
            <div className="modal-content">
              {showLogin && (
                <>
                  <div className="modal-content-title">Inloggen</div>

                  {!loading ? (
                    <>
                      <span>
                        Je moet eerst inloggen met de code die je in de
                        uitnodiging hebt gehad om een cadeau te kunnen
                        reserveren.
                      </span>
                      {loginError && (
                        <span className="error">
                          Geen geldige code gebruikt
                        </span>
                      )}
                      <div className="modal-content-form">
                        <input
                          type="password"
                          placeholder="Code"
                          ref={passkey}
                        />
                        <button onClick={formSubmitHandler}>
                          Code invullen
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <span style={{ marginBottom: "20px" }}>
                        Proberen in te loggen...
                      </span>
                      <Spinner />
                    </>
                  )}
                </>
              )}
              {!showLogin && (
                <div className="modal-content-checked">
                  <i className="fa-solid fa-check" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="container" onClick={() => setClickedGift(null)}>
        <h1>{title}</h1>
        <div className="gifts-content">
          {gifts &&
            gifts.map((gift, index) => (
              <div
                onClick={(e) => e.stopPropagation()}
                className={`gifts-content-gift ${
                  gift.id === clickedGift ? "clickedOnMobile" : ""
                }`}
                key={index}
              >
                <div
                  className={`gifts-content-gift-image ${
                    gift.checked && "gifts-content-gift-image-checked"
                  }`}
                >
                  <img
                    src={`https://admin.keesenbo.nl/images/${gift.image}`}
                    alt={gift.title}
                  />
                </div>
                {!gift.checked && (
                  <div
                    className="gifts-content-gift-overlay"
                    onClick={() => (onMobile ? handleClick(gift) : null)}
                  >
                    {gift.title}
                    {gift.toReservate && (
                      <button
                        onClick={(e) =>
                          gift.id === clickedGift && handleReservation(e, gift)
                        }
                      >
                        Reserveren
                      </button>
                    )}
                    {gift.url && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          return window.open(gift.url, "_blank");
                        }}
                      >
                        Bekijken
                      </button>
                    )}
                  </div>
                )}
                {gift.checked && (
                  <div className="gifts-content-gift-checked">
                    <i className="fa-solid fa-check" />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
