import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const defaultProgram = [
  {
    image: "",
    title: "",
    date: "1-1-1999",
    time: "0:00",
    location: "",
  },
];

export const Program = ({ title }) => {
  const [program, setProgram] = useState(defaultProgram);

  const fetchProgram = () => {
    fetch(`https://admin.keesenbo.nl/api/programs.json`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setProgram(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchProgram();
  }, []);

  return (
    <section className="program">
      <div className="container">
        <h1>{title}</h1>
        <div className="program-content">
          {program &&
            program.map((programItem, index) => (
              <div className="program-content-item" key={index}>
                {programItem.image && (
                  <div
                    className={`program-content-item-image ${
                      index % 2 && "--reverse"
                    }`}
                  >
                    <img
                      src={`https://admin.keesenbo.nl/images/${programItem.image}`}
                      alt={`programItem_${index}`}
                    />
                  </div>
                )}
                <div className="program-content-item-block">
                  <div
                    className={`program-content-item-block-textblock ${
                      index % 2 && "--reverse"
                    } ${!programItem.image && "--noimage"}`}
                  >
                    <div className="program-content-item-block-textblock-title">
                      {programItem.title}
                    </div>
                    <div className="program-content-item-block-textblock-datetime">
                      <div className="program-content-item-block-textblock-datetime-date">
                        {`${new Date(programItem.date).getDate()} ${new Date(
                          programItem.date
                        ).toLocaleString("nl-NL", {
                          month: "long",
                        })} ${new Date(programItem.date).getFullYear()}`}
                      </div>
                      <div className="program-content-item-block-textblock-datetime-time">
                        {`${
                          (new Date(programItem.time).getHours() < 10
                            ? "0"
                            : "") + new Date(programItem.time).getHours()
                        }:${
                          (new Date(programItem.time).getMinutes() < 10
                            ? "0"
                            : "") + new Date(programItem.time).getMinutes()
                        } uur`}
                      </div>
                    </div>
                    <div
                      className="program-content-item-block-textblock-text"
                      dangerouslySetInnerHTML={{ __html: programItem.text }}
                    />
                    {programItem.location && (
                      <div className="program-content-item-block-textblock-location">
                        {programItem.location_url ? (
                          <Link to={programItem.location_url} target="_blank">
                            <i class="fa-solid fa-location-dot" />
                            <span
                              dangerouslySetInnerHTML={{
                                __html: programItem.location,
                              }}
                            />
                          </Link>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: programItem.location,
                            }}
                          />
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
