import { useEffect, useState } from "react";
import { Menu } from "./Menu";
import { Flowers } from "./flowers";
import React from "react";
import { MasterOfCeremony } from "./masterofceremony";

export const Page = ({ content }) => {
  const [settings, setSettings] = useState();

  useEffect(() => {
    fetch(`https://admin.keesenbo.nl/api/settings.json?id=1`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setSettings(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      <Flowers />
      <main>
        <Menu />
        <div className="content">
          {React.cloneElement(content, { settings })}
        </div>
        {/* {settings &&
          settings.map((setting, index) => {
            // <div>{console.log(master)}</div>
            return setting.mastersOfCeremony.map((master, index) => (
              <FloatingWhatsApp
                key={index}
                phoneNumber={master.telephonenumber}
                accountName={`${master.forname} ${master.surname}`}
                avatar={`https://admin.keesenbo.nl/images/${master.image}`}
                chatMessage="Hallo, hoe kan ik je helpen?"
                statusMessage="Reactie meestal binnen 24 uur"
                allowClickAway={true}
                allowEsc={true}
              />
            ));
          })} */}
        <MasterOfCeremony settings={settings} />
      </main>
    </>
  );
};
