import { useEffect, useRef, useState } from "react";
import {
  TTL_PASSKEY,
  getWithExpiry,
  setWithExpiry,
} from "../components/helpers";

export const Rsvp = ({ title }) => {
  const passkey = useRef();
  const comingWithInput = useRef();
  const ceremonyCheckBox = useRef();
  const receptionCheckbox = useRef();
  const dinerCheckbox = useRef();
  const partyCheckbox = useRef();
  const dietInput = useRef();
  const emailaddressInput = useRef();
  const phonenumberInput = useRef();
  const favoriteSongsInput = useRef();
  const commentsInput = useRef();

  const [invitee, setInvitee] = useState(null);
  const [ceremony, setCeremony] = useState();
  const [reception, setReception] = useState();
  const [diner, setDiner] = useState();
  const [party, setParty] = useState();
  const [emailaddress, setEmailaddress] = useState();
  const [phonenumber, setPhonenumber] = useState();
  const [saved, setSaved] = useState(false);
  const [error, setError] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const fetchInviteeData = (key) => {
    fetch(`https://admin.keesenbo.nl/user`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: JSON.stringify({ passkey: key }),
    })
      .then((response) => response.json())
      .then((data) => {
        setInvitee(data.data);
        setWithExpiry("passkey", key, TTL_PASSKEY);
        setCeremony(data.data.ceremony);
        setReception(data.data.reception);
        setDiner(data.data.diner);
        setParty(data.data.party);
        setEmailaddress(data.data.emailaddress);
        setPhonenumber(data.data.phonenumber);
      })
      .catch((error) => {
        console.error(error);
        setLoginError(true);
      });
  };

  const removeGift = (giftId) => {
    localStorage.removeItem("reservationTTL");
    const passkeyFromStorage = getWithExpiry("passkey");
    if (passkeyFromStorage) {
      fetch(`https://admin.keesenbo.nl/emailremovegift`, {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: JSON.stringify({
          passkey: passkeyFromStorage,
          giftId: giftId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
        .catch((error) => {
          console.error(error);
        });

      fetch(`https://admin.keesenbo.nl/removegift`, {
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
        body: JSON.stringify({
          passkey: passkeyFromStorage,
          giftId: giftId,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          fetchInviteeData(passkeyFromStorage);
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    const passkeyFromStorage = getWithExpiry("passkey");
    if (passkeyFromStorage) {
      fetchInviteeData(passkeyFromStorage);
    }
  }, []);

  const checkPasskeyHandler = (e) => {
    e.preventDefault();
    fetchInviteeData(passkey.current.value);
  };

  const updatePhoneNumber = () => {
    setPhonenumber(phonenumberInput.current.value);
  };

  const updateEmailAddress = () => {
    setEmailaddress(emailaddressInput.current.value);
  };

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setSaved(false);
    setError(false);

    fetch(`https://admin.keesenbo.nl/email`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: JSON.stringify({
        passkey: getWithExpiry("passkey"),
        coming_with: comingWithInput.current.value,
        diet: dietInput.current?.value ? dietInput.current?.value : "",
        ceremony: ceremonyCheckBox.current?.checked
          ? ceremonyCheckBox.current.checked
          : false,
        reception: receptionCheckbox.current?.checked
          ? receptionCheckbox.current.checked
          : false,
        diner: dinerCheckbox.current?.checked
          ? dinerCheckbox.current.checked
          : false,
        party: partyCheckbox.current?.checked
          ? partyCheckbox.current.checked
          : false,
        emailaddress: emailaddressInput.current.value,
        phonenumber: phonenumberInput.current.value,
        favorite_songs: favoriteSongsInput.current.value,
        comments: commentsInput.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error(error);
      });

    fetch(`https://admin.keesenbo.nl/updateuser`, {
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      body: JSON.stringify({
        passkey: getWithExpiry("passkey"),
        coming_with: comingWithInput.current.value,
        diet: dietInput.current?.value ? dietInput.current?.value : "",
        ceremony: ceremonyCheckBox.current?.checked
          ? ceremonyCheckBox.current.checked
          : false,
        reception: receptionCheckbox.current?.checked
          ? receptionCheckbox.current.checked
          : false,
        diner: dinerCheckbox.current?.checked
          ? dinerCheckbox.current.checked
          : false,
        party: partyCheckbox.current?.checked
          ? partyCheckbox.current.checked
          : false,
        emailaddress: emailaddressInput.current.value,
        phonenumber: phonenumberInput.current.value,
        favorite_songs: favoriteSongsInput.current.value,
        comments: commentsInput.current.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.response === "OK") {
          setSaved(true);
          window.scrollTo(0, 0);

          return;
        }
        setError(true);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
        window.scrollTo(0, 0);
      });
  };

  const getSalution = () => {
    if (invitee.spouse && invitee.child_1 && invitee.child_2) {
      return `${invitee.forename}, ${invitee.spouse}, ${invitee.child_1} & ${invitee.child_2}`;
    }
    if (invitee.spouse && invitee.child_1 && !invitee.child_2) {
      return `${invitee.forename}, ${invitee.spouse} & ${invitee.child_1}`;
    }
    if (invitee.spouse && !invitee.child_1 && !invitee.child_2) {
      return `${invitee.forename} & ${invitee.spouse}`;
    }
    if (!invitee.spouse && !invitee.child_1 && !invitee.child_2) {
      return `${invitee.forename}`;
    }
    if (!invitee.spouse && !invitee.child_1 && invitee.child_2) {
      return `${invitee.forename} & ${invitee.child_2}`;
    }
    if (!invitee.spouse && invitee.child_1 && !invitee.child_2) {
      return `${invitee.forename} & ${invitee.child_1}`;
    }
    if (!invitee.spouse && invitee.child_1 && invitee.child_2) {
      return `${invitee.forename}, ${invitee.child_1} & ${invitee.child_2}`;
    }
  };

  const updateCheckbox = (partOfTheDay) => {
    if (partOfTheDay === "de ceremonie") {
      setCeremony(!ceremony);
    }
    if (partOfTheDay === "de receptie") {
      setReception(!reception);
    }
    if (partOfTheDay === "het diner") {
      setDiner(!diner);
    }
    if (partOfTheDay === "het feest") {
      setParty(!party);
    }
  };

  return (
    <section className="rsvp">
      <div className="container">
        <h1>{title}</h1>
        <div className="rsvp-content">
          {!invitee && (
            <div className="rsvp-content-passkey">
              {loginError && (
                <div className="rsvp-content-invitation-section">
                  <div className="rsvp-content-invitation-section-error">
                    Er is helaas iets fout gegaan met het inloggen.
                    <br />
                    Neem contact op met onze ceremoniemeester om je status door
                    te geven.
                  </div>
                </div>
              )}
              Je moet eerst even inloggen voordat je je aanwezigheid door kan
              geven. De code die je hiervoor nodig hebt staat op de achterkant
              van het fotootje bij de uitnodiging.
              <div className="rsvp-content-passkey-code">Code:</div>
              <input type="password" name="passkey" ref={passkey} />
              <br />
              <button onClick={checkPasskeyHandler}>Inloggen</button>
            </div>
          )}
          {invitee && (
            <div className="rsvp-content-invitation">
              {saved && (
                <div className="rsvp-content-invitation-section">
                  <div className="rsvp-content-invitation-section-saved">
                    Bedankt voor het doorgeven!
                    <br />
                    De gegevens zijn succesvol opgeslagen!
                  </div>
                </div>
              )}
              {error && (
                <div className="rsvp-content-invitation-section">
                  <div className="rsvp-content-invitation-section-error">
                    Er is helaas iets fout gegaan met het opslaan.
                    <br />
                    Neem contact op met onze ceremoniemeester om je status door
                    te geven.
                  </div>
                </div>
              )}
              {invitee.updated_at && (
                <div className="rsvp-content-invitation-section">
                  <div className="rsvp-content-invitation-section-update">
                    Je hebt dit voor het laatst geupdate op {invitee.updated_at}
                    .
                  </div>
                </div>
              )}
              <div className="rsvp-content-invitation-section">
                Hoi {getSalution()},
              </div>
              <div className="rsvp-content-invitation-section">
                {invitee.spouse || invitee.child_1 || invitee.child_2
                  ? "Jullie zijn "
                  : "Je bent "}
                van harte uitgenodigd op{" "}
                {Object.values(invitee.invited_to).map(
                  (partOfTheDay, index) => {
                    return (
                      <span key={index}>
                        {index === 0
                          ? partOfTheDay
                          : index !==
                            Object.values(invitee.invited_to).length - 1
                          ? `, ${partOfTheDay}`
                          : ` & ${partOfTheDay}`}
                      </span>
                    );
                  }
                )}{" "}
                van onze bruiloft.
              </div>
              Wij zouden het fijn vinden als je hieronder even laat weten met
              hoeveel personen je komt (jezelf meegerekend!)
              <div className="rsvp-content-invitation-section">
                <div>
                  <select
                    name="invitations"
                    ref={comingWithInput}
                    defaultValue={invitee.coming_with}
                  >
                    {[...Array(invitee.invitations + 1)].map((e, i) => (
                      <option value={i} key={i}>
                        {i} {i === 1 ? "persoon" : "personen"}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="rsvp-content-invitation-section">
                En op welk gedeelte van de dag kom je?
              </div>
              <div className="rsvp-content-invitation-section">
                {Object.values(invitee.invited_to).map(
                  (partOfTheDay, index) => {
                    return (
                      <div key={index}>
                        <input
                          type="checkbox"
                          name={partOfTheDay}
                          onClick={() => updateCheckbox(partOfTheDay)}
                          ref={
                            (partOfTheDay === "de ceremonie" &&
                              ceremonyCheckBox) ||
                            (partOfTheDay === "de receptie" &&
                              receptionCheckbox) ||
                            (partOfTheDay === "het diner" && dinerCheckbox) ||
                            (partOfTheDay === "het feest" && partyCheckbox)
                          }
                          defaultChecked={
                            (partOfTheDay === "de ceremonie" && ceremony) ||
                            (partOfTheDay === "de receptie" && reception) ||
                            (partOfTheDay === "het diner" && diner) ||
                            (partOfTheDay === "het feest" && party)
                          }
                        />
                        <label htmlFor={partOfTheDay}>{partOfTheDay}</label>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="rsvp-content-invitation-section">
                {Object.values(invitee.invited_to).includes("het diner") && (
                  <div className="rsvp-content-invitation-section">
                    Zijn er nog allergieen of dieetwensen waar wij rekening mee
                    moeten houden?
                    <div>
                      <textarea
                        name="diet"
                        ref={dietInput}
                        defaultValue={invitee.diet}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="rsvp-content-invitation-section">
                Mogen we ook je contactgegevens zodat onze ceremoniemeester
                eventueel contact met je kan opnemen
                <br />
                <br />
                Emailadres:{" "}
                <input
                  type="email"
                  value={emailaddress}
                  ref={emailaddressInput}
                  onChange={updateEmailAddress}
                />
                <br />
                <br />
                Telefoonnummer:{" "}
                <input
                  type="tel"
                  value={phonenumber}
                  ref={phonenumberInput}
                  onChange={updatePhoneNumber}
                />
              </div>
              <div className="rsvp-content-invitation-section">
                En met welke nummers krijgen we jou op de dansvloer?
                <div>
                  <textarea
                    name="favorite_songs"
                    ref={favoriteSongsInput}
                    defaultValue={invitee.favorite_songs}
                  />
                </div>
              </div>
              <div className="rsvp-content-invitation-section">
                Is er nog iets wat je met ons of onze ceremoniemeester wilt
                delen
                <div>
                  <textarea
                    name="comments"
                    ref={commentsInput}
                    defaultValue={invitee.comments}
                  />
                </div>
              </div>
              {invitee.presents.length > 0 && (
                <div className="rsvp-content-invitation-section">
                  {invitee.presents.length > 1 ? "Deze cadeau's" : "Dit cadeau"}{" "}
                  heb je gereserveerd:
                  {invitee.presents.map((present) => (
                    <div
                      className="rsvp-content-invitation-section-gift"
                      key={present.id}
                      onClick={() => removeGift(present.id)}
                    >
                      <i className="fa-solid fa-xmark" /> {present.title}
                    </div>
                  ))}
                </div>
              )}
              <button onClick={formSubmitHandler}>Opslaan</button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
