import { Link } from "react-router-dom";
import { Logo } from "./Logo";
import { useState } from "react";

export const Menu = () => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="menu">
      <Logo />
      <div className="menu-mobile" onClick={toggleMenu}>
        menu
      </div>
      <div className={`menu-content ${showMenu && "showMobile"}`}>
        <Link onClick={toggleMenu} to={"/"} className="menu-content-item">
          Home
        </Link>
        <Link
          onClick={toggleMenu}
          to={"/onsverhaal"}
          className="menu-content-item"
        >
          Ons verhaal
        </Link>
        <Link
          onClick={toggleMenu}
          to={"/programma"}
          className="menu-content-item"
        >
          Programma
        </Link>
        <Link
          onClick={toggleMenu}
          to={"/demensen"}
          className="menu-content-item"
        >
          De mensen
        </Link>
        <Link
          onClick={toggleMenu}
          to={"/praktischeinformatie"}
          className="menu-content-item"
        >
          Praktische informatie
        </Link>
        <Link onClick={toggleMenu} to={"/rsvp"} className="menu-content-item">
          RSVP
        </Link>
        <Link
          onClick={toggleMenu}
          to={"/cadeautips"}
          className="menu-content-item"
        >
          Cadeautips
        </Link>
      </div>
      <div className="menu-flower">
        <img src="flower_line.png" alt={"flower"} />
      </div>
    </div>
  );
};
