import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CountdownTimer = ({ settings }) => {
  const navigate = useNavigate();
  const calculateTimeLeft = (weddingDate) => {
    if (!weddingDate) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const now = new Date().getTime();
    const target = new Date(weddingDate).getTime();

    const difference = target - now;

    if (difference <= 0) {
      // Timer expired
      setTimerExpired(true);
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const [timerExpired, setTimerExpired] = useState(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(settings[0].weddingDate));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return (
    <>
      <section className="countdown">
        <div className="home">
          <button onClick={() => navigate("/rsvp")}>
            Laat <u>hier</u> weten of je komt!
          </button>
        </div>
        {timerExpired ? (
          <p>Timer expired!</p>
        ) : (
          <div className="countdown-timer">
            <div className="countdown-timer-days">
              <div className="countdown-timer-days-numbers">
                <div className="countdown-timer-days-numbers-number">
                  {timeLeft.days >= 100
                    ? timeLeft.days.toString().substring(0, 1)
                    : "0"}
                </div>
                <div className="countdown-timer-days-numbers-number">
                  {timeLeft.days >= 100
                    ? timeLeft.days.toString().substring(1, 2)
                    : timeLeft.days >= 10
                    ? timeLeft.days.toString().substring(0, 1)
                    : "0"}
                </div>
                <div className="countdown-timer-days-numbers-number">
                  {timeLeft.days >= 100
                    ? timeLeft.days.toString().substring(2, 3)
                    : timeLeft.days >= 10
                    ? timeLeft.days.toString().substring(1, 2)
                    : timeLeft.days.toString().substring(0, 1)}
                </div>
              </div>
              <div className="countdown-timer-days-text">dagen</div>
            </div>
            <div className="countdown-timer-hours">
              <div className="countdown-timer-hours-numbers">
                <div className="countdown-timer-hours-numbers-number">
                  {timeLeft.hours >= 10
                    ? timeLeft.hours.toString().substring(0, 1)
                    : "0"}
                </div>
                <div className="countdown-timer-hours-numbers-number">
                  {timeLeft.hours >= 10
                    ? timeLeft.hours.toString().substring(1, 2)
                    : timeLeft.hours.toString().substring(0, 1)}
                </div>
              </div>
              <div className="countdown-timer-hours-text">uren</div>
            </div>
            <div className="countdown-timer-minutes">
              <div className="countdown-timer-minutes-numbers">
                <div className="countdown-timer-minutes-numbers-number">
                  {timeLeft.minutes >= 10
                    ? timeLeft.minutes.toString().substring(0, 1)
                    : "0"}
                </div>
                <div className="countdown-timer-minutes-numbers-number">
                  {timeLeft.minutes >= 10
                    ? timeLeft.minutes.toString().substring(1, 2)
                    : timeLeft.minutes.toString().substring(0, 1)}
                </div>
              </div>
              <div className="countdown-timer-minutes-text">minuten</div>
            </div>
            <div className="countdown-timer-seconds">
              <div className="countdown-timer-seconds-numbers">
                <div className="countdown-timer-seconds-numbers-number">
                  {timeLeft.seconds >= 10
                    ? timeLeft.seconds.toString().substring(0, 1)
                    : "0"}
                </div>
                <div className="countdown-timer-seconds-numbers-number">
                  {timeLeft.seconds >= 10
                    ? timeLeft.seconds.toString().substring(1, 2)
                    : timeLeft.seconds.toString().substring(0, 1)}
                </div>
              </div>
              <div className="countdown-timer-seconds-text">seconden</div>
            </div>
          </div>
        )}
        <img src="headerimage.jpeg" alt="headerimage" />
      </section>
    </>
  );
};

export default CountdownTimer;
