import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./errorPage";
import PhotoViewer from "./Pages/Photoviewer";
import { Rsvp } from "./Pages/Rsvp";
import "./styles/app.scss";
import { Gifts } from "./Pages/Gifts";
import CountdownTimer from "./components/CountdownTimer";
import { Page } from "./components/Page";
import { Story } from "./Pages/Story";
import { Program } from "./Pages/Program";
import { People } from "./Pages/People";
import { PracticalInformation } from "./Pages/PracticalInformation";
import { PrimeReactProvider } from "primereact/api";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Page content={<CountdownTimer />} />,
    errorElement: <Page content={<ErrorPage />} />,
  },
  {
    path: "/slideshow",
    element: (
      <Page
        content={
          <div className="thedate">
            <img src="thedate.png" alt="thedate" />
            <PhotoViewer />
          </div>
        }
      />
    ),
  },
  {
    path: "/rsvp",
    element: <Page content={<Rsvp title={"RSVP"} />} />,
  },
  {
    path: "/cadeautips",
    element: <Page content={<Gifts title={"Cadeautips"} />} />,
  },
  {
    path: "/onsverhaal",
    element: <Page content={<Story title={"Ons verhaal"} />} />,
  },
  {
    path: "/programma",
    element: <Page content={<Program title={"Het programma"} />} />,
  },
  {
    path: "/demensen",
    element: <Page content={<People title={"De mensen"} />} />,
  },
  {
    path: "/praktischeinformatie",
    element: (
      <Page
        content={<PracticalInformation title={"Praktische informatie"} />}
      />
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrimeReactProvider>
      <RouterProvider router={router} />
    </PrimeReactProvider>
  </React.StrictMode>
);
