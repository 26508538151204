import { useEffect, useState } from "react";

const defaultPeople = [
  {
    name: "",
    title: "",
    text: "",
    image: "",
  },
];

export const People = ({ title }) => {
  const [people, setPeople] = useState(defaultPeople);

  const fetchPeople = () => {
    fetch(`https://admin.keesenbo.nl/api/peoples.json`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        setPeople(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchPeople();
  }, []);

  return (
    <section className="people">
      <div className="container">
        <h1>{title}</h1>
        <div className="people-content">
          {people &&
            people.map((person, index) => (
              <div className="people-content-item" key={index}>
                <div className="people-content-item-image">
                  <img
                    src={`https://admin.keesenbo.nl/images/${person.image}`}
                    alt={`programItem_${index}`}
                  />
                </div>
                <div className="people-content-item-textblock">
                  <div className="people-content-item-textblock-name">
                    {person.name}
                  </div>
                  <div className="people-content-item-textblock-title">
                    {person.title}
                  </div>
                  <div
                    className="people-content-item-textblock-text"
                    dangerouslySetInnerHTML={{ __html: person.text }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
