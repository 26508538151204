export const Flowers = () => {
  return (
    <div className="flowers">
      <div className="flowers-bottom">
        <img src="flowers_bottom-01.png" alt="flowers_bottom" />
      </div>
      <div className="flowers-top">
        <img src="flowers_top-01.png" alt="flowers_top" />
      </div>
    </div>
  );
};
