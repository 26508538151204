const useFetch = () => {
  const get = async (endpoint) => {
    return fetch(`https://admin.keesenbo.nl/api/${endpoint}.json`)
      .then((res) => res.json())
      .then((data) => {
        return data;
      });
  };

  return { get };
};

export default useFetch;
